@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
  margin: 0;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  background: rgb(254,231,0);
  min-height: 100vh;
  background: radial-gradient(circle, rgba(254,231,0,1) 0%, #f9be24 100%);
  text-align: center;
}

h1 {
  font-size: 3rem;
  font-weight: 800;
  font-style: oblique;
}

h3 {
  font-size: 1rem;
  font-weight: 300;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

h4 {
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
}

.btn {
  font-weight: 600;
  border: white 1px solid !important;
}

.home__countdown {
  padding-top: 10vh;
}

.full_page_loader {
  margin-top: 40vh;
  width: 5rem;
  height: 5rem;
  color: black;
}


/* Start */

.start__annagrams {
  font-size: 0.9rem;
}

.start__grocery-list-img {
  position: relative;
  width: 70%;
  object-fit: cover;
}

.start__countdown {
  padding-top: 20vh;
}

.start__countdown__subheading {
  margin-top: -0.5rem;
  font-size: 0.6rem;
  font-weight: bolder;
  font-style: oblique;
}

.start__countdown__value {
  margin-bottom: 0px;
  font-size: 2.5rem;
  font-weight: bolder;
}

.start__countdown__colon {
  margin: auto;
  font-size: 1.5rem;
  font-weight: bolder;
}

.start__grocery-list {
  padding: 5%;
  background-color: rgba(255,255,255,0.8);
  border-radius: 8px;
}

.start__grocery-list > h4 {
  text-align: center;
}

.start__grocery-list__form {
  display: inline;
  max-width: 15rem;
  text-align: center;
  margin-top: 1rem;
  transition: none;
}

.start__grocery-list > .is-valid {
  color: rgb(0, 190, 0);
  border: rgb(0, 190, 0) 2px solid !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}

.start__grocery-list > .is-invalid {
  color: #dc3545;
  border: #dc3545 2px solid;
}

/* Dashboard */

.dashboard-tile > p {
  text-align: left;
  margin: 0;
}

.dashboard-tile > h4 {
  font-weight: 700;
}

.dashboard-tile .progress-bar {
  background-color: #4b158a;
}

/* Override */ 

.form-control {
  border-radius: 10px;
  border: 1px solid #ced4da;
}

.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.remove-radius--right {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.remove-radius--left {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.light-text-override {
  font-weight: lighter;
}

.progress-bar {
  transform: rotate(180deg);
}

.progress-bar > span {
  transform: rotate(180deg);
}

.fa-map-marker {
  font-size: 2rem;
}